import "./css/MovieCardProfileMobile.css"
import { useCallback, useEffect, useState } from "react";
import MovieModel from "../../../models/MovieModel";
import { Rating } from "@mui/material";

export const MovieCardProfileMobile: React.FC<{ movie: MovieModel }> = (props) => {
    const [rating, setRating] = useState<number>(0);
  
    const fetchRating = useCallback(async () => {
      try {
        const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/movie/${props.movie.movie_id}/ratings/average`;
        const response = await fetch(url);
        const data = await response.text();
        const apiRating: number = parseFloat(data);
        setRating(apiRating);
      } catch (error) {
        console.error("Error fetching rating:", error);
      }
    }, [props.movie.movie_id]);
  
    useEffect(() => {
      fetchRating();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchRating]);
  
    const handleRatingChange = async (event: React.SyntheticEvent, value: number | null) => {
      if(value !== null) {
        setRating(value);
      }
  
      try {
        const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/movie/add-rating`;
        const payload = {
          movieId: props.movie.movie_id,
          ratingValue: value,
        };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          await fetchRating();
        } else {
          console.error("Failed to update rating:", response.statusText);
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    };
  
    return (
        <div className="movie-card-mobile">
        <div className="movie-card-mobile-thumbnail">
            <img
                src={`https://img.youtube.com/vi/${props.movie.movie_poster}/mqdefault.jpg`}
                alt='Movie Poster'
            />
        </div>
        <div className="movie-card-mobile-body">
            <span className="movie-card-mobile-title">{props.movie.title}</span>
            <p>
                <span>{isNaN(props.movie.release_year) || props.movie.release_year == null ? ' ' : `${props.movie.release_year},`}</span>
                <span>{props.movie.episodes.length} Episodes</span>
            </p>
            <span className='rating-stars'><Rating name="half-rating" value={rating} precision={0.5} onChange={handleRatingChange}/></span>
            {/* <p className="movie-card-mobile-desctiption"> 
                <span>Cast:</span>
                Tom Harry, Jon Park, Luke Skywalker, David Johnson, 
                Tom Harry, Jon Park, Luke Skywalker, David Johnson, 
                Tom Harry, Jon Park, Luke Skywalker, David Johnson
            </p> */}
        </div>
    </div>
    );
  };
   