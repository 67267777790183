import React, { useState } from "react";
import "./css/VideoPlayer.css";
import { CookieYoutubePlayer } from "./CookieYoutubePlayer";

interface VideoPlayerProps {
  videoId: string;
  height: string;
  width: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoId, height, width
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className='video-container'>
      {!isPlaying ? (
        <div className='thumbnail'>
          <img
            src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
            alt='Movie Poster'
          />
          <button className='play-button' onClick={handlePlayClick}></button>
        </div>
      ) : (
        <div className='video-player'>
          <CookieYoutubePlayer videoId={videoId} height={height} width={width} />
        </div>
      )}
    </div>
  );
};
